@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@100;200;300;400&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400')

$domain: 'https://widget.readloud.ru'

.readloud-player.player-wrapper
  font-family: Montserrat
  min-width: 320px
  width: 100%
  .readloud-player-advertising
    background-color: #FFFFFF
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25)
    border-radius: 8px
    padding: 8px
    margin-bottom: 8px
  .player
    box-sizing: border-box
    padding: 13px 20px
    background-color: #FFFFFF
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25)
    border-radius: 8px
    display: grid
    grid-template-columns: 1fr 75px
    grid-gap: 10px
    align-items: center
    justify-content: space-between
    width: 100%
    position: relative
    color: #C4C4C4
    &.dark
      color: #FFFFFF
      background-color: #313131
      .icon
        background-color: #FFFFFF
      .logo
        background-color: #FFFFFF
    &.pink
      color: #FF0860
      background-color: #FFFFFF
      .icon
        background-color: #FF0860
      .logo
        background-color: #FF0860
    .icon
      width: 24px
      height: 24px
      background-color: #000000
      cursor: pointer
    .logo
      position: absolute
      bottom: 4px
      left: 50%
      transform: translate(-50%)
      width: 49px
      height: 8px
      -webkit-mask-image: url(#{$domain}/assets/logo.svg)
      -webkit-mask-size: contain
      -webkit-mask-position: center
      -webkit-mask-repeat: no-repeat
      background-color: #C4C4C4
    .left
      display: grid
      grid-template-columns: 24px 5fr 40px
      grid-gap: 10px
      align-items: center
      .play-or-pause
        .play
          -webkit-mask-image: url(#{$domain}/assets/play.svg)
        .pause
          -webkit-mask-image: url(#{$domain}/assets/pause.svg)
      .time-counter
        font-size: 14px
        line-height: 100%
    .right
      display: flex
      justify-content: flex-end
      grid-gap: 10px
      align-items: center
      .speed-counter
        font-weight: 500
        font-size: 24px
        line-height: 100%
        cursor: pointer
        &.hidden
          opacity: 0
      .volume
        position: relative
        display: flex
        grid-gap: 10px
        align-items: center
        .volume-line
          width: 50px
        .volume-icon
          -webkit-mask-image: url(#{$domain}/assets/volume-full.svg)
          &.volume-icon-mid
            -webkit-mask-image: url(#{$domain}/assets/volume-mid.svg)
          &.volume-icon-mute
            -webkit-mask-image: url(#{$domain}/assets/volume-mute.svg)
      .close
        -webkit-mask-image: url(#{$domain}/assets/close.svg)
    .slider-line
      width: 100%
      display: flex
      align-items: center
      position: relative
      &.for-dark-player
        .circle
          background-color: #FFFFFF
        input
          &::-webkit-slider-thumb
            background-color: #FFFFFF
            box-shadow: -400px 0 0 400px #FFFFFF
          &::-webkit-slider-runnable-track
            background-color: #797979
          &::-moz-range-thumb
            background-color: #FFFFFF
            box-shadow: -400px 0 0 400px #FFFFFF
          &::-moz-range-track
            height: 2px
            background-color: #797979
      &.for-pink-player
        .circle
          background-color: #FF0860
        input
          &::-webkit-slider-thumb
            background-color: #FF0860
            box-shadow: -400px 0 0 400px #FF0860
          &::-webkit-slider-runnable-track
            background-color: #F9B6CE
          &::-moz-range-thumb
            background-color: #FF0860
            box-shadow: -400px 0 0 400px #FF0860
          &::-moz-range-track
            height: 2px
            background-color: #F9B6CE
      .circle
        position: absolute
        width: 5px
        height: 5px
        background-color: #000000
        border-radius: 50%
        cursor: pointer
        pointer-events: none
        transform: translate(-2.5px)
      input
        -webkit-appearance: none
        width: 100%
        cursor: pointer
        height: 2px
        overflow: hidden
        &::-webkit-slider-thumb
          -webkit-appearance: none
          background-color: #000000
          width: 0
          height: 0
          border-radius: 50%
          box-shadow: -400px 0 0 400px #000000
        &::-webkit-slider-runnable-track
          -webkit-appearance: none
          height: 2px
          background-color: #C4C4C4
        &::-moz-range-thumb
          width: 0
          height: 0
          background-color: #000000
          border: none
          border-radius: 50%
          box-shadow: -400px 0 0 400px #000000
        &::-moz-range-track
          height: 2px
          background-color: #C4C4C4
  .play-button
    display: flex
    grid-gap: 12px
    padding: 8px 12px
    align-items: center
    background: #FFFFFF
    border: 1px solid #FFBFD4
    box-sizing: border-box
    border-radius: 4px
    cursor: pointer
    .icon
      width: 24px
      height: 24px
      -webkit-mask-size: contain
      -webkit-mask-position: center
      -webkit-mask-repeat: no-repeat
      background-color: #000000
      &.logo-animate
        display: flex
        background-image: url(#{$domain}/assets/logo-letter-animate.gif)
        background-size: contain
        background-position: center
      &.logo
        -webkit-mask-image: url(#{$domain}/assets/logo-letter.svg)
    .play
      -webkit-mask-image: url(#{$domain}/assets/play.svg)
    p
      white-space: nowrap
      font-size: 14px
      line-height: 100%

.readloud-loader-main-loader
  height: 20px
  width: 20px

.readloud-loader-loader
  position: relative
  margin: 0px auto
  width: 20px
  height: 20px

.readloud-loader-circular-loader 
  -webkit-animation: rotate 2s linear infinite
          animation: rotate 2s linear infinite
  height: 100%
  -webkit-transform-origin: center center
      -ms-transform-origin: center center
          transform-origin: center center
  width: 100%
  position: absolute
  top: 0
  left: 0
  margin: auto

.readloud-loader-loader-path
  stroke-dasharray: 150,200
  stroke-dashoffset: -10
  stroke: #000000
  animation: dash 1.5s ease-in-out infinite
  stroke-linecap: round


@keyframes rotate
  100% 
    -webkit-transform: rotate(360deg)
            transform: rotate(360deg)

@keyframes dash
  0%
    stroke-dasharray: 1,200
    stroke-dashoffset: 0
  50%
    stroke-dasharray: 89,200
    stroke-dashoffset: -35
  100%
    stroke-dasharray: 89,200
    stroke-dashoffset: -124

.readloud-player.readloud-player-black.player-wrapper
  .readloud-player-advertising
    background-color: #313131
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25)
  .player
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25)
    color: #FFFFFF
    background-color: #313131
    .icon
      background-color: #FFFFFF
    .logo
      background-color: #FFFFFF
    &.pink
      color: #FF0860
      background-color: #FFFFFF
      .icon
        background-color: #FF0860
      .logo
        background-color: #FF0860
    .slider-line
      .circle
        background-color: #FFFFFF
      input
        &::-webkit-slider-thumb
          background-color: #FFFFFF
          box-shadow: -400px 0 0 400px #FFFFFF
        &::-webkit-slider-runnable-track
          background-color: #797979
        &::-moz-range-thumb
          background-color: #FFFFFF
          box-shadow: -400px 0 0 400px #FFFFFF
        &::-moz-range-track
          height: 2px
          background-color: #797979
      &.for-pink-player
        .circle
          background-color: #FF0860
        input
          &::-webkit-slider-thumb
            background-color: #FF0860
            box-shadow: -400px 0 0 400px #FF0860
          &::-webkit-slider-runnable-track
            background-color: #F9B6CE
          &::-moz-range-thumb
            background-color: #FF0860
            box-shadow: -400px 0 0 400px #FF0860
          &::-moz-range-track
            height: 2px
            background-color: #F9B6CE
  .play-button
    background: #FFFFFF
    border: 1px solid #FFBFD4
    .icon
      background-color: #FFFFFF
  .readloud-loader-loader-path
    stroke: #FFFFFF

.readloud-player.readloud-player-pink.player-wrapper
  .readloud-player-advertising
    background-color: #FFFFFF
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25)
  .player
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25)
    color: #FF0860
    background-color: #FFFFFF
    .icon
      background-color: #FF0860
    .logo
      background-color: #FF0860
    .slider-line
      .circle
        background-color: #FF0860
      input
        &::-webkit-slider-thumb
          background-color: #FF0860
          box-shadow: -400px 0 0 400px #FF0860
        &::-webkit-slider-runnable-track
          background-color: #F9B6CE
        &::-moz-range-thumb
          background-color: #FF0860
          box-shadow: -400px 0 0 400px #FF0860
        &::-moz-range-track
          height: 2px
          background-color: #F9B6CE
  .play-button
    background: #FFFFFF
    border: 1px solid #FFBFD4
    .icon
      background-color: #FF0860
  .readloud-loader-loader-path
    stroke: #FF0860

#readloud-advertising-link, #readloud-advertising-video
  display: block
  margin: 0 auto